import React from "react";
import ReactDOM from "react-dom";
import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline";
import WaveSurferRegions from "wavesurfer.js/dist/plugin/wavesurfer.regions";
import CursorWave from "wavesurfer.js/dist/plugin/wavesurfer.cursor";

export default class Waveform extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            regionLoop: false,
            regionStart: 0,
            regionEnd: 5
        };
        this.wrapper = React.createRef();
    }
    componentDidMount() {
        this.$el = this.wrapper.current;
        this.$waveform = this.$el.querySelector(".wave");
        this.$wavetimeline = this.$el.querySelector(".wave-timeline");
        console.log(this.$wavetimeline)
        this.wavesurfer = WaveSurfer.create({
            container: this.$waveform,
            waveColor: "violet",
            progressColor: "purple",
            plugins: [
                CursorWave.create({
                    showTime: true,
                    opacity: 1,
                    customShowTimeStyle: {
                        'background-color': '#000',
                        color: '#fff',
                        padding: '2px',
                        'font-size': '10px',
                        height: '20px'
                    }
                }),
                WaveSurferRegions.create({
                        regions: [
                            {
                                start: 0,
                                end: 5,
                                color: 'hsla(400, 100%, 30%, 0.1)',
                                loop: this.regionLoop
                            }
                            
                    ]
                    }),
                TimelinePlugin.create({
                    container: this.$wavetimeline,
                    formatTimeCallback: this.formatTimeCallback,
                    timeInterval: this.timeInterval,
                    primaryLabelInterval: this.primaryLabelInterval,
                    secondaryLabelInterval: this.secondaryLabelInterval,
                    primaryColor: "blue",
                    secondaryColor: "red",
                    primaryFontColor: "blue",
                    secondaryFontColor: "red",
                }),
            ],
        });
        this.wavesurfer.load(this.props.src);
    }

    formatTimeCallback = (seconds, pxPerSec) => {
      seconds = Number(seconds);
      var minutes = Math.floor(seconds / 60);
      seconds = seconds % 60;
  
      // fill up seconds with zeroes
      var secondsStr = Math.round(seconds).toString();
      if (pxPerSec >= 25 * 10) {
          secondsStr = seconds.toFixed(2);
      } else if (pxPerSec >= 25 * 1) {
          secondsStr = seconds.toFixed(1);
      }
  
      if (minutes > 0) {
          if (seconds < 10) {
              secondsStr = '0' + secondsStr;
          }
          return `${minutes}:${secondsStr}`;
      }
      return secondsStr;
  }
  
  /**
   * Use timeInterval to set the period between notches, in seconds,
   * adding notches as the number of pixels per second increases.
   *
   * Note that if you override the default function, you'll almost
   * certainly want to override formatTimeCallback, primaryLabelInterval
   * and/or secondaryLabelInterval so they all work together.
   *
   * @param: pxPerSec
   */
  timeInterval = (pxPerSec) => {
      var retval = 1;
      if (pxPerSec >= 25 * 100) {
          retval = 0.01;
      } else if (pxPerSec >= 25 * 40) {
          retval = 0.025;
      } else if (pxPerSec >= 25 * 10) {
          retval = 0.1;
      } else if (pxPerSec >= 25 * 4) {
          retval = 0.25;
      } else if (pxPerSec >= 25) {
          retval = 1;
      } else if (pxPerSec * 5 >= 25) {
          retval = 5;
      } else if (pxPerSec * 15 >= 25) {
          retval = 15;
      } else {
          retval = Math.ceil(0.5 / pxPerSec) * 60;
      }
      return retval;
  }
  
  /**
   * Return the cadence of notches that get labels in the primary color.
   * EG, return 2 if every 2nd notch should be labeled,
   * return 10 if every 10th notch should be labeled, etc.
   *
   * Note that if you override the default function, you'll almost
   * certainly want to override formatTimeCallback, primaryLabelInterval
   * and/or secondaryLabelInterval so they all work together.
   *
   * @param pxPerSec
   */
  primaryLabelInterval = (pxPerSec) => {
      var retval = 1;
      if (pxPerSec >= 25 * 100) {
          retval = 10;
      } else if (pxPerSec >= 25 * 40) {
          retval = 4;
      } else if (pxPerSec >= 25 * 10) {
          retval = 10;
      } else if (pxPerSec >= 25 * 4) {
          retval = 4;
      } else if (pxPerSec >= 25) {
          retval = 1;
      } else if (pxPerSec * 5 >= 25) {
          retval = 5;
      } else if (pxPerSec * 15 >= 25) {
          retval = 15;
      } else {
          retval = Math.ceil(0.5 / pxPerSec) * 60;
      }
      return retval;
  }
  
  /**
   * Return the cadence of notches to get labels in the secondary color.
   * EG, return 2 if every 2nd notch should be labeled,
   * return 10 if every 10th notch should be labeled, etc.
   *
   * Secondary labels are drawn after primary labels, so if
   * you want to have labels every 10 seconds and another color labels
   * every 60 seconds, the 60 second labels should be the secondaries.
   *
   * Note that if you override the default function, you'll almost
   * certainly want to override formatTimeCallback, primaryLabelInterval
   * and/or secondaryLabelInterval so they all work together.
   *
   * @param pxPerSec
   */
  secondaryLabelInterval = (pxPerSec) => {
      // draw one every 10s as an example
      return Math.floor(10 / this.timeInterval(pxPerSec));
  }
  
    handleTogglePlay = () => {
        this.setState({
            playing: !this.state.playing,
        });
        if (!this.state.playing) {
            this.wavesurfer.play();
        } else {
            this.wavesurfer.pause();
        }
    };

    playNote = () => {
        this.setState({
            playing: true,
            regionLoop: true
        },() =>
        {
            this.wavesurfer.seekTo(this.state.regionStart); 
            this.wavesurfer.play();
        }
        );
        console.log(this.wavesurfer.getCurrentTime());
    }

    handlePosChange(e) {
        this.setState({
            pos: e.originalArgs[0],
        });
    }
    render() {
        return (
            <>
            <div className='waveform' ref={this.wrapper}>
                <div className='wave'></div>
                <div className='wave-timeline'></div>
            </div>
            <button onClick={this.handleTogglePlay}>
                {this.state.playing ? "Pause" : "Play"}
            </button>
            <div className="notes">
                Note region: <div style={{width:'12px', height: '22px',background:'rgba(153, 102, 0, 0.4)'}}/>
                <button onClick={this.playNote}>Play Note</button>
            </div>
            </>
        );
    }
}

Waveform.defaultProps = {
    src: "",
};
