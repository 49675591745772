import React from 'react';
import logo from './logo.svg';
import './App.css';
import ViewAudio from './components/ViewAudio';

function App() {
  return (
    <div className="App">
      Audio Notes Maker
      <ViewAudio/>
    </div>
  );
}

export default App;
