import React from "react";
import ReactDOM from "react-dom";
import WaveSurfer from "wavesurfer.js";
import ReactWaveSurfer from "react-wavesurfer";
import Waveform from "./WaveForm";

class ViewAudio extends React.Component {
    constructor(props) {
        super(props);
        this.audioRef = React.createRef();

        this.state = {
            playing: false,
            pos: 0,
        };
        this.handleTogglePlay = this.handleTogglePlay.bind(this);
        this.handlePosChange = this.handlePosChange.bind(this);
    }

    componentDidMount() {
        console.log(this.audioRef);
        // WaveSurfer.create({
        //     container: this.audioRef.current,
        //     waveColor: "purple",
        //     progressColor: "red",
        // });
        // WaveSurfer.load('static/audio/demo.wav')
    }
    handleTogglePlay() {
        this.setState({
            playing: !this.state.playing,
        });
    }
    handlePosChange(e) {
        this.setState({
            pos: e.originalArgs[0],
        });
    }
    render() {
        return (
            <div ref={this.audioRef}>
                <Waveform
                src='static/audio/demo.wav'
                />
                {/* <ReactWaveSurfer
                    ref={this.audioRef}
                    audioFile={'static/audio/demo.wav'}
                    pos={this.state.pos}
                    onPosChange={this.handlePosChange}
                    playing={this.state.playing}
                /> */}
            </div>
        );
    }
}

export default ViewAudio;
